
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { BagOrSttNumber } from "@/data/payload/api/CargoApiRequest";
import {
  CargoData,
  CargoDetailStt,
  DimensionData
} from "@/domain/entities/Cargo";
import { ResponseBagSttDetail } from "@/domain/entities/ResponsePayload";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import { gtmCargoData } from "../../modules/gtm";
import NotificationCommodityGrouping from "@/app/ui/components/notification-commodity-group/index.vue";
import uniqBy from "lodash/uniqBy";
import capitalize from "lodash/capitalize";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import { flags } from "@/feature-flags";
import { TrackingController } from "@/app/ui/controllers/TrackingController";
import formatInputDecimalOnly2Digits from "@/app/infrastructures/misc/common-library/formatInputDecimalOnly2Digits";
import { checkValueOnBlurInput } from "@/app/infrastructures/misc/Utils";
import { convertDecimalWithComma } from "@/app/infrastructures/misc/Utils";
import CargoFlightManualSection from "@/app/ui/views/cargo/booking-cargo/third-step/cargo-flight-manual-section.vue";
import { FlightDetail } from "@/domain/entities/NGen";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { defineAsyncComponent } from "@vue/runtime-core";
const Gamification = defineAsyncComponent(() =>
  import("@/app/ui/views/cargo/booking-cargo/components/gamification-rtc-modal/index.vue")
);

@Options({
  emits: ["dataChange"],
  components: {
    NotificationCommodityGrouping,
    CargoFlightManualSection,
    Gamification
  }
})
export default class FormSection extends Vue {
  async mounted() {
    if (!this.form.isChangeAirport) {
      if (this.flowBooking == "rtc-edit") {
        this.mappingReadyToCargo();
      }
      if (this.$route.query.rcId && this.cargoInputForm.id == 0 && !CargoController.isDeleteStt) {
        await CargoController.getDetailRetryCargo({
          cargoNo: this.$route.query.rcId.toString(),
          isPrint: false
        });
        this.mappingSttResultFromDetail();
      }
      this.setVolumeWeight();
      this.resetBaggingGroupingAndDate();
      if (this.sttResult.length) this.autoFillDestination(this.sttResult[0]);
    }
    this.updateEstimationVolumeAndWeight();
    this.getCargoProductList();
    this.onSetCommodityGroup();
    if (this.flowBooking === 'rtc-edit' || this.isCutoffPlaneRTC) {
       CargoController.calculationVolumeWeight(this.totalPcs)
    }
  }

  get isCutoffPlaneRTC() {
    return this.flowBooking === 'rtc-process' && !this.detailRTC.cutOffTime && this.detailRTC.cargoType === 'plane'
  }

  get cargoInputFormData() {
    return CargoController.cargoInputForm;
  }

  get detailRTC() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  onResetAirport() {
    this.form.airports = [];
  }

  get dataDetailRetryCargo() {
    return CargoController.cargoDetailData;
  }

  // destination city
  get destinationCity() {
    return CargoController.destinationCity;
  }

  fetchDestinationCity = debounce((search: string) => {
    LocationController.getCityList({
      search: search,
      status: "",
      page: 1,
      limit: 10
    });
  }, 250);

  showInfoDividerCargo = true;

  get form() {
    return CargoController.form;
  }

  get detailData(): CargoData {
    return CargoController.cargoDetailData;
  }

  get isLoadingCity() {
    return LocationController.isLoadingDestinationCity;
  }

  get optionDestinationCity() {
    return CargoController.optionDestinationCity;
  }

  get Dimension() {
    return CargoController.CargoInputData.dimension;
  }

  get totalGrossWeights() {
    return CargoController.totalGrossWeights || "0";
  }

  get totalVolumeWeights() {
    return CargoController.totalVolumeWeights;
  }

  changeDestinationCity(item: any) {
    this.form.destinationCityCode = item.code;
    this.form.destinationCityName = item.name;
    this.getBaggingGroupingLocation(this.form.destinationCityCode);
    CargoController.setDestinationCity(item);
    this.$emit("dataChange");
  }

  public setPeriodeStart(date: Date) {
    const estDepartureDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    const estArrivalDate = this.$moment(date)
      .add(6, "days")
      .toISOString();
    this.form.estDepartureDate = estDepartureDate;
    this.form.estArrivalDate = estArrivalDate;

    const cargoInputForm = JSON.parse(
      localStorage.getItem("cargo-input-data") || "{}"
    );
    cargoInputForm.estDepartureDate = estDepartureDate;
    cargoInputForm.estArrivalDate = estArrivalDate;
    storeDatatoLocalStorage("cargo-input-data", cargoInputForm);
  }

  get isDisableAddSttNumber() {
    return !this.scanNumber;
  }

  convertProduct(name: string) {
    return name.toLowerCase().includes("express") ? "Express" : "REGULAR";
  }

  totalPiecesBagOrStt = 0;

  async addSttNumberToUpdate() {
    const isCheckTrackingSttFirst = !this.checkSttorBagFormatNumber(
      this.sttNumber.trim()
    ); // this is for checking if it need to use tracking stt endpoint or not

    const isSttFromLilo = this.checkSttFromLilo(this.sttNumber.trim());

    const inputStt = this.sttNumber;
    let generatedStt = this.scanNumber.trim();

    if (isCheckTrackingSttFirst && !isSttFromLilo) {
      await TrackingController.trackStt({
        sttNo: this.sttNumber.trim(),
        isGetSla: false,
        isSecondCall: false,
        cache: true
      }).then(isSuccess => {
        if (isSuccess) {
          this.sttNumber = this.sttDetail.sttNo;
          generatedStt = this.sttDetail.sttNo;
        }
      });
    }

    let bagNumber = "";
    let sttNumber = "";
    const isSttOrBag = /^\d/.test(this.sttNumber);

    if (!isSttOrBag) {0
      bagNumber = this.sttNumber.trim();
    } else {
      sttNumber = this.sttNumber.trim();
    }

    await CargoController.appendBagOrSttNumber({
      bagOrStt: new BagOrSttNumber(bagNumber, sttNumber),
      cargoProductType: this.convertProduct(this.form.cargoProduct),
      cargoCommodity: this.form.cargoCommodityCode ?? "",
      cargoType: this.form.cargoType,
      callback: this.addStt
    }).then(() => {
      if (isCheckTrackingSttFirst && !isSttFromLilo) {
        this.sttResult.map(item => {
          if (item.sttNumber.toLowerCase() == generatedStt.toLowerCase()) {
            item.sttNoRefExternal = inputStt.trim();
          }
          return item;
        });
      }
      return;
    });
    CargoController.resetTotalColiOnLocalStorage();
    this.onSetCommodityGroup();
    this.onChangeTotalColi(CargoController.totalSttPieces);
    CargoController.calculationVolumeWeight(this.totalPcs);
    CargoController.setIsModalGamificationDone(true);
  }

  sttNumber = "";
  scanNumber = ""
  isScan = false;

  checkSttorBagFormatNumber(value: string) {
    const sttPatern = /^\d{2}[a-z]{2}/; // check for stt number
    const bagPattern = /^[a-z]{3}-\d+$/; // check for bag number
    return sttPatern.test(value.toLocaleLowerCase()) || bagPattern.test(value.toLocaleLowerCase());
  }

  checkSttFromLilo(value: string) {
    const liloPattern = /tkp01-bag/i; // check for lilo number
    return liloPattern.test(value.toLowerCase());
  }

  addScanSttNumber(val: any) {
    if (!this.isDisableAddSttNumber) {
      const splitVal = val.split("#");
      this.sttNumber = `${splitVal[0]}`;
      this.isScan = true;
      this.blurInputStt();
      this.sttNumber && this.addSttNumberToUpdate();
    }
  }

  blurInputStt() {
    const inputStt: any = this.$refs.inputStt;
    inputStt?.blur();
  }

  addSttNumber() {
    if (!this.isDisableAddSttNumber) {
      this.isScan = false;
      const splitVal = this.scanNumber.split("#");
      this.sttNumber = `${splitVal[0]}`;
      this.blurInputStt();
      this.sttNumber && this.addSttNumberToUpdate();
    }
  }

  get errorTypeAppend(): string {
    return CargoController.errorCauseAppend;
  }

  addStt(fetchBagOrStt: any) {
    if (fetchBagOrStt || this.errorTypeAppend === "server") {
      const detailSTT = CargoController.bagSttDetail;

      if (detailSTT.isAllowUpdateStatus) {
        this.onProcessCargoData(detailSTT);
      } else {
        playNotification("error");
        this.errorSttNumber = detailSTT.errorMessage;
      }
    }
  }

  // handle stt number
  errorSttNumber = "";
  isValidateScan = false;
  isErrorValidationScan = false;
  isUnpaid = false;
  isErrorNotification = false;

  savedSttNo = "";
  savedCargoType = "";
  savedCargoNumber = "";

  onProcessCargoData(detailSTT: any) {
    for (const detail of detailSTT.bagOrStt) {
      this.onValidateSttResult(detailSTT, detail);
      if (this.isValidateScan) {
        this.detailSttNotification = {
          sttNumber: detail.stt_no,
          product: detail.product_type,
          commodity: detail.commodity_name,
          totalPieces: detail.stt_total_piece,
          destinationCity: this.destinationCity?.name,
          commodityGroupName: detail.commodity_group_name
        };
        this.detailNotificationCommodityGrouping = {
          sttCommodityGroupName: detail.commodity_group_name,
          message: this.$t("Komoditas kargo tidak sama")
        };

        // GTM
        if (this.form.cargoType && this.form.cargoType !== "ship") {
          const gtmInputSttSuccess = gtmCargoData({
            type: this.form.cargoType,
            isAuto: this.isPlaneAuto
          }).inputSttSuccess;

          dataLayer(
            gtmInputSttSuccess.eventName,
            gtmInputSttSuccess.property,
            []
          );
        }
        this.onValidateCommodityGrouping(detail);
      }
    }
    if (this.isValidateScan) {
      const getGrossWeight =
        detailSTT.bagOrStt[0].bag_data.bag_custom_gross_weight ??
        detailSTT.bagOrStt[0].gross_weight;
      const totalActualGrossWeight =
        this.cargoInputForm.calcuateActualCargoGrossWeight + getGrossWeight; // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285584448
      CargoController.setTotalActualGrossWeight(totalActualGrossWeight.toFixed(2));
      this.updateEstimationVolumeAndWeight();
    }
    this.onSuccessAddStt();
  }

  onValidateSttResult(detailSTT: any, detail: any) {
    const isScanBag = !/^\d/.test(this.sttNumber);
    const indexCurrentSttNumber = this.sttResult.findIndex(data => {
      return data.sttNumber === detail.stt_no;
    });

    if (indexCurrentSttNumber > -1) {
      if (this.sttResult[indexCurrentSttNumber].action === "remove") {
        this.sttResult[indexCurrentSttNumber].action = "";
      } else {
        playNotification("error");
        this.errorSttNumber = this.$t("No. STT sudah di input");
        this.isValidateScan = false;
        return;
      }
    } else if (!detail.is_paid) {
      this.isUnpaid = true;
      this.isErrorNotification = true;
      this.messageErrorNotification =
        "Tunggu sampai customers selesai melunasi tagihan kurang bayar";
      this.errorSttNumber =
        "Terjadi kelebihan berat sehingga status tidak dapat diupdate ke Cargo.";
      playNotification("error");
    } else {
      if (isScanBag) {
        this.appendBagResult(
          detailSTT.bagOrStt[0]?.bag_no || detailSTT.bagOrStt[0]?.bag_vendor_no,
          detailSTT
        );
      }
      this.isCacheCommodityGroup = false;
      this.sttResult.push(
        new CargoDetailStt({
          baggingNumber: detail.bag_no,
          baggingVendorNo: detail.bag_vendor_no,
          sttNumber: detail.stt_no,
          totalPieces: detail.stt_total_piece,
          grossWeigth: detail.gross_weight,
          volumeWeight: detail.volume_weight,
          productType: detail.product_type,
          commodity: detail.commodity_name,
          commodityGroupCode: detail.commodity_group_code,
          commodityGroupName: detail.commodity_group_name,
          destinationCityCode: detail.destination_city_code,
          regionId: detail.region_id,
          shcCode: detail.shc_code,
          shcName: detail.shc_description
        })
      );
      if (detail.region_id) {
        this.autoFillDestination(detail);
      }
      this.$emit("dataChange");
      playNotification("success");
    }
    this.isValidateScan = true;
  }
  onValidateCommodityGrouping(detail: any) {
    const res = isNaN(+this.actualTotalCargoGrossWeight)
      ? +this.actualTotalCargoGrossWeight.replace(",", ".") < 10
      : +this.actualTotalCargoGrossWeight < 10;
    this.setShowNotificationWeight(res && !this.isErrorNotification);
    if (this.sttResult.length > 1 && !this.isErrorNotification) {
      if (
        this.sttResult[0].commodityGroupCode !== detail.commodity_group_code
      ) {
        this.detailNotificationCommodityGrouping = {
          sttCommodityGroupName: this.sttResult[0].commodityGroupName,
          message: this.$t("Komoditas kargo tidak sama")
        };
        this.notificationCommodityGrouping = true;
      } else {
        this.notificationCommodityGrouping = false;
      }
    }
  }
  onSuccessAddStt() {
    // reset form
    this.savedSttNo = this.sttNumber;
    this.scanNumber = "";
    this.notification = this.isValidateScan;

    if (!this.isErrorValidationScan) {
      this.isValidateScan = false;
    }
    // re focus
    const inputStt: any = this.$refs.inputStt;
    inputStt.focus();

    // remove notification after 15 second
    if (!this.isUnpaid) {
      setTimeout(() => {
        this.notification = false;
        this.notificationCommodityGrouping = false;
      }, 15000);
    }
  }

  // notification handler
  notification = false;
  detailSttNotification: any = null;
  notificationCommodityGrouping = false;
  detailNotificationCommodityGrouping: any = null;
  messageErrorNotification = "";

  onCloseNotification() {
    this.isUnpaid = false;
    this.isErrorNotification = false;
    this.isDiffDestination = false;
    this.errorSttNumber = "";
    this.notification = false;
  }

  appendBagResult(bagNo: string, detailSTT: ResponseBagSttDetail) {
    if (!this.bagResult.find(item => item.baggingNumber === bagNo)) {
      this.bagResult.push({
        baggingNumber: bagNo,
        bagGrossWeight: detailSTT.bagOrStt[0].bag_data.bag_custom_gross_weight,
        stt: detailSTT.bagOrStt.map(
          (item: any) =>
            new CargoDetailStt({
              baggingNumber: item.bag_no,
              baggingVendorNo: item.bag_vendor_no,
              sttNumber: item.stt_no,
              totalPieces: item.stt_total_piece,
              grossWeigth: item.gross_weight,
              volumeWeight: item.volume_weight,
              productType: item.product_type,
              commodity: item.commodity_name,
              commodityGroupCode: item.commodity_group_code,
              commodityGroupName: item.commodity_group_name
            })
        )
      });
      CargoController.setBagResult(this.bagResult);
    }
  }

  get sttDetail() {
    return TrackingController.trackingSttData.sttDetail;
  }

  get bagResult(): {
    baggingNumber: string;
    bagGrossWeight: number;
    stt: CargoDetailStt[];
  }[] {
    storeDatatoLocalStorage("cargo-bag", CargoController.bagResult);
    return CargoController.bagResult;
  }

  get sttResult(): CargoDetailStt[] {
    storeDatatoLocalStorage("cargo", CargoController.sourceSttFromLocalStorage);
    return CargoController.sourceSttFromLocalStorage;
  }

  set sttResult(newSttResult: CargoDetailStt[]) {
    storeDatatoLocalStorage("cargo", newSttResult);
    CargoController.setSourceFromLocalStorage(newSttResult);
  }

  maxTotalBagOrSttForPlane = false;

  get sttResultFinal() {
    const tempData: any = [];
    if (this.sttResult) {
      this.sttResult.forEach((e: any) => {
        const existingIdx = tempData.findIndex(
          (a: any) => a.baggingNumber === e.baggingNumber
        );
        if (e.baggingNumber && !e.sttDetails) {
          if (existingIdx > -1) {
            tempData[existingIdx]["sttDetails"].push(e);
          } else {
            tempData.push({ ...e, sttDetails: [e] });
          }
          return;
        }
        tempData.push(e);
      });
    }
    CargoController.setSTTResultFinal(tempData);
    return tempData;
  }

  // airport
  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }

  get actualTotalCargoGrossWeight() {
    let total = CargoController.CargoInputData.totalActualCargoGrossWeight;
    if (CargoController.isEditReadyToCargo) {
      total = ReadyToCargoController.readyToCargoDetail.totalWeight;
    }
    if (CargoController.CargoInputData.totalActualCargoGrossWeight) {
      total = CargoController.CargoInputData.totalActualCargoGrossWeight;
    }
    this.form["actualTotalCargoGrossWeight"] = String(total);
    return String(total);
  }

  set actualTotalCargoGrossWeight(str: string) {
    this.form.actualTotalCargoGrossWeight = str;
  }

  calculationVolumeWeight() {
    CargoController.calculationVolumeWeight(this.totalPcs);
  }

  setVolumeWeight() {
    if (this.flowBooking == "manual") {
      this.form.actualDimension = {
        length: this.cargoInputFormData.dimension.length || 30,
        width: this.cargoInputFormData.dimension.width || 20,
        height: this.cargoInputFormData.dimension.height || 10
      };
    } else {
      this.form.actualDimension = {
        length: this.form.actualDimension.length || 30,
        width: this.form.actualDimension.width || 20,
        height: this.form.actualDimension.height || 10
      };
      this.cargoInputForm.dimension = this.form.actualDimension
    }
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  set actualTotalSTTPieces(value: number) {
    this.form.actualTotalSTTPieces = value;
  }

  get totalPcs() {
    let total = CargoController.totalSttPieces;
    if (this.cargoInputForm.totalActualCargoColi != 0) {
      total = CargoController.cargoInputForm.totalActualCargoColi;
    }
    this.actualTotalSTTPieces = total;
    return total;
  }

  set totalPcs(num: number) {
    this.form.actualTotalSTTPieces = num;
    CargoController.setOnChangeTotalColi(this.form.actualTotalSTTPieces);
  }

  numberOnly(value: string) {
    return value
      .replace(/[^0-9,.]+/, "")
      .replace(/\.+/g, ",")
      .replace(/,(.+),/g, ",$1");
  }

  formatFloat(value: string) {
    return formatInputDecimalOnly2Digits(value);
  }

  formatValue(value: string, type: string) {
    if (checkValueOnBlurInput(value) === "withoutComma") {
      const newValue = Number(value).toFixed(2).replace(".", ",");;
      if (type === "grossWeight") {
        CargoController.CargoInputData.totalActualCargoGrossWeight = newValue;
        this.form.actualTotalCargoGrossWeight = newValue;
        this.cargoInputForm.totalActualCargoGrossWeight = newValue;
      } else {
        this.form.actualTotalCargoVolumeWeight = newValue;
        this.cargoInputForm.totalActualCargoVolumeWeight = newValue;
      }
      storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    } else if (checkValueOnBlurInput(value) === "oneValueAfterComma") {
      if (type === "grossWeight") {
        CargoController.CargoInputData.totalActualCargoGrossWeight = value + "0";
        this.form.actualTotalCargoGrossWeight = value + "0";
        this.cargoInputForm.totalActualCargoGrossWeight = value + "0";
      } else {
        this.form.actualTotalCargoVolumeWeight = value + "0";
        this.cargoInputForm.totalActualCargoVolumeWeight = value + "0";
      }
      storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    } else if (checkValueOnBlurInput(value) === "minimumValue") {
      const newValueMinimum = value.split(",")[0];
      if (type === "grossWeight") {
        CargoController.CargoInputData.totalActualCargoGrossWeight = newValueMinimum + ",10";
        this.form.actualTotalCargoGrossWeight = newValueMinimum + ",10";
        this.cargoInputForm.totalActualCargoGrossWeight = newValueMinimum + ",10";
      } else {
        this.form.actualTotalCargoVolumeWeight = newValueMinimum + ",10";
        this.cargoInputForm.totalActualCargoVolumeWeight = newValueMinimum + ",10";
      }
      storeDatatoLocalStorage("cargo-input-data", this.cargoInputForm);
    }
  }

  isDiffDestination = false;

  getBaggingGroupingLocation(code: string) {
    return CargoController.getRegionBaggingGrouping(code);
  }

  setShowNotesDestination(bool: boolean) {
    CargoController.setShowNotesDestination(bool);
  }

  get regionBaggingGrouping() {
    return CargoController.regionBaggingGrouping;
  }

  get showNotesDestination() {
    return CargoController.showNotesDestination;
  }

  get loadingRegion() {
    return CargoController.loadingRegion;
  }

  get dataProfile() {
    return CargoController.dataProfile;
  }

  get showRecommendationRegion() {
    return (
      this.form.destinationCityCode &&
      !this.loadingRegion &&
      this.showNotesDestination &&
      this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.regionBaggingGrouping.baggingRegionDestinationName &&
      this.form.destinationCityCode !==
        this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.dataProfile.account_location.city_code !==
        this.regionBaggingGrouping.baggingRegionDestinationId &&
      this.regionBaggingGrouping.baggingRegionOriginId !==
        this.regionBaggingGrouping.baggingRegionDestinationId
    );
  }

  autoFillDestination(detailSTT: any) {
    CargoController.autoFillDestination(detailSTT);
  }

  get showNotificationWeight() {
    return CargoController.showNotificationWeight;
  }

  setShowNotificationWeight(val: boolean) {
    CargoController.setNotificationWeight(val);
  }

  mappingSttResultFromDetail() {
    const destinationParam = new OptionsClass({
      code: this.dataDetailRetryCargo.destinationCityCode,
      label: `${this.dataDetailRetryCargo.destinationCityCode} - ${capitalize(
        this.dataDetailRetryCargo.destinationCityName.toLowerCase()
      )}`,
      name: this.dataDetailRetryCargo.destinationCityName
    });
    this.form.actualDimension =
      this.dataDetailRetryCargo.actualDimension || new DimensionData();
    this.form.actualTotalCargoVolumeWeight = this.dataDetailRetryCargo.totalActualCargoVolumeWeight;
    if (this.$route.query.rcId) {
      CargoController.setTotalActualGrossWeight(
        Number(this.detailData.totalActualCargoGrossWeight.toString().replace(",","."))
      );
      this.form.nog = this.dataDetailRetryCargo.nog || "";
      this.form.cargoProduct = this.dataDetailRetryCargo.cargoProduct;
      this.form.vehicleNumber = this.dataDetailRetryCargo.vehiclePoliceNumber;
      CargoController.setCargoType({type: "plane", status: "auto"})
      this.form.shcCode = this.dataDetailRetryCargo.shcCode;
      this.form.shcName = this.dataDetailRetryCargo.shcDescription;
      CargoController.setIsFromRebookingRTC(this.dataDetailRetryCargo.isFromRtc);
      const newSttResult: CargoDetailStt[] = [];
      this.dataDetailRetryCargo.detailStt.forEach((detail: CargoDetailStt) => {
        newSttResult.push(
          new CargoDetailStt({
            baggingNumber: detail.baggingNumber,
            sttNumber: detail.sttNumber,
            totalPieces: detail.totalPieces,
            grossWeigth: detail.grossWeigth,
            volumeWeight: detail.volumeWeight,
            productType: detail.productType,
            commodity: detail.commodity,
            destination: detail.destination,
            destinationCityCode: detail.destinationCityCode,
            destinationCityName: detail.destinationCityName,
            regionId: detail.regionId,
            commodityGroupCode: this.dataDetailRetryCargo.cargoCommodityCode,
            commodityGroupName: this.dataDetailRetryCargo.cargoCommodity,
            isFromRebooking: true,
            sttNoRefExternal: detail.sttNoRefExternal,
            shcCode : this.dataDetailRetryCargo.shcCode,
            shcName: this.dataDetailRetryCargo.shcDescription
          })
        );
      });
      this.sttResult = newSttResult;
    }
    CargoController.setDestinationCity(
      !this.$route.query.rcId ? "" : destinationParam
    );
  }

  resetBaggingGroupingAndDate() {
    CargoController.setShowNotesDestination(false);
    this.form.estDepartureDate = new Date(this.form.estDepartureDate);
  }

  mappingReadyToCargo() {
    CargoController.mappingReadyToCargo();
  }

  // commodity group
  isDisableCommodityGroup = false;

  get shcValue() {
    return this.form.shcCode && this.form.shcName ? `${this.form.shcCode} - ${this.form.shcName}` : "-";
  }

  commodityOptions: OptionsClass[] = [];
  onSetCommodityGroup() {
    CargoController.setDisableCommodityGroup(false);

    // mapping each bagging's stt
    const mappingdata: CargoDetailStt[] = [];
    for (const item of this.sttResultFinal) {
      if (item?.sttDetails?.length) {
        for (const detail of item.sttDetails) {
          mappingdata.push(detail);
        }
      } else {
        mappingdata.push(item);
      }
    }

    // filter result of mapping data by commodity group
    const getUniqueList: any = uniqBy(
      mappingdata.filter((item: CargoDetailStt) => item.commodityGroupCode),
      "commodityGroupCode"
    );

    // count total duplicate commodity group
    const countGroupByCommodity = mappingdata.reduce(
      (accumulator: any, item: CargoDetailStt) => {
        return (
          (accumulator[item.commodityGroupName] =
            (accumulator[item.commodityGroupName] || 0) + 1),
          accumulator
        );
      },
      {}
    );

    // convert object total to array of object
    const totalDataByCommodityGroup = Object.entries(
      countGroupByCommodity
    ).map((item: any[]) => ({ name: item[0], total: item[1] }));

    // find max of total
    const max = totalDataByCommodityGroup.reduce(
      (prev: any, current: any) =>
        prev.total > current.total ? prev : current,
      0
    );

    // find all index if possible max is more than 1
    const maxIndexLocation = totalDataByCommodityGroup.reduce(
      (accumulator: any, currentValue, currentIndex) =>
        currentValue.total === max.total
          ? accumulator.concat(currentIndex)
          : accumulator,
      []
    );

    // if only one max, will set default value
    if (maxIndexLocation.length === 1) {
      // get detail data of max
      const dominanData: CargoDetailStt = getUniqueList[maxIndexLocation[0]];
      this.isDisableCommodityGroup = false;

      // set to default value
      this.commodityItem = new OptionsClass({
        name: dominanData?.commodityGroupName,
        code: dominanData?.commodityGroupCode,
        shcCode: dominanData?.shcCode,
        shcName: dominanData?.shcName
      });
      this.form.cargoCommodityName = dominanData?.commodityGroupName;
      this.form.cargoCommodityCode = dominanData?.commodityGroupCode || "";
      this.form.shcName = dominanData?.shcName || "";
      this.form.shcCode = dominanData?.shcCode || "";
      this.setCargoComodity(this.commodityItem);
      CargoController.setDisableCommodityGroup(true);
    }

    if (maxIndexLocation !== 1) {
      const dominanData: CargoDetailStt = getUniqueList[maxIndexLocation[0]];
      this.isDisableCommodityGroup = false;

      this.commodityItem = new OptionsClass({
        name: dominanData?.commodityGroupName,
        code: dominanData?.commodityGroupCode,
        shcCode: dominanData?.shcCode,
        shcName: dominanData?.shcName
      });
      this.form.cargoCommodityName = dominanData?.commodityGroupName;
      this.form.cargoCommodityCode = dominanData?.commodityGroupCode || "";
      this.form.shcName = dominanData?.shcName || "";
      this.form.shcCode = dominanData?.shcCode || "";
      this.setCargoComodity(this.commodityItem);
    }

    if (this.commodityItem.name != "") {
      this.form.cargoCommodityName = this.commodityItem.name;
      this.form.cargoCommodityCode = this.commodityItem.name;
      this.form.shcName = this.commodityItem.shcName;
      this.form.shcCode = this.commodityItem.shcCode;
      this.setCargoComodity(this.commodityItem);
    }

    this.commodityOptions = getUniqueList.map(
      (item: CargoDetailStt) =>
        new OptionsClass({
          name: item.commodityGroupName,
          value: item.commodityGroupCode,
          shcCode: item.shcCode,
          shcName: item.shcName
        })
    );
  }

  get disableCommodityGroup(): boolean {
    return CargoController.disableCommodityGroup;
  }
  commodityItem: OptionsClass =
    CargoController.cargoInputForm.cargoComodity || new OptionsClass();

  onSelectCommodity(val: any) {
    if (val.value === this.commodityItem.value) {
      this.commodityItem = {
        value: "",
        name: ""
      };
      this.form.cargoCommodityName = "";
      this.form.cargoCommodityCode = "";
      this.form.shcCode = "";
      this.form.shcName = "";
      return;
    }
    this.commodityItem = val;
    this.form.cargoCommodityName = val.name;
    this.form.cargoCommodityCode = val.value;
    this.form.shcName = val.shcName;
    this.form.shcCode = val.shcCode;
    this.isCacheCommodityGroup = true;
    const comodity = new OptionsClass({
      name: val.name,
      value: val.value,
      shcCode: val.shcCode,
      shcName: val.shcName
    });
    this.setCargoComodity(comodity);
  }

  setCargoComodity(value: OptionsClass) {
    CargoController.setCargoComodity(value);
  }

  set isCacheCommodityGroup(value: boolean) {
    CargoController.setCacheCommodityGroup(value);
  }

  onChangeTotalCargoGrossWeight(value: any) {
    CargoController.setOnChangeTotalActualGrossWeight(value);
  }

  onChangeDimension(value: DimensionData) {
    CargoController.setIsChangeVolumeActual(false);
    CargoController.setOnChangeDimension(value);
  }

  onChangeTotalColi(value: number) {
    CargoController.setOnChangeTotalColi(value);
  }

  onChangeTotalCargoVolumeWeight(value: number) {
    CargoController.setIsChangeVolumeActual(true);
    CargoController.setOnChangeTotalCargoVolumeWeight(value);
  }

  set setEstimationTotalVolumeWeight(value: any) {
    CargoController.setEstimationTotalVolumeWeight(value);
  }

  set setEstimationTotalGrossWeight(value: any) {
    CargoController.setEstimationTotalGrossWeight(value);
  }

  updateEstimationVolumeAndWeight() {
    this.setEstimationTotalVolumeWeight = this.totalVolumeWeights;
    this.setEstimationTotalGrossWeight = this.totalGrossWeights;
  }

  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  get isErrorDiffPiecesActualThanDimensionPieces() {
    if (this.showButtonDimension) {
      return false;
    }
    return CargoController.isErrorDiffPiecesActualThanDimensionPieces;
  }

  showPopupDimensionPieces(val: boolean) {
    CargoController.setShowPopupDimensionPieces(val);
    if (!this.isHaveBeenSubmitDetailPieces) {
      CargoController.formDetailPieces.totalPieces = this.totalPcs;
    }
  }

  get showButtonDimension() {
    return flags.feature_hide_dimension_koli.isEnabled();
  }

  get isCargoTrain() {
    return this.form.cargoType === "train";
  }

  get isCargoShip() {
    return this.form.cargoType === "ship";
  }

  get isCargoTruck() {
    return this.form.cargoType === "truck";
  }

  get isCargoPlaneManual() {
    return (
      this.form.cargoType === "plane" &&
      this.form.cargoTypeStatus !== "auto"
    );
  }

  get isCargoPlaneAuto() {
    return (
      this.form.cargoType === "plane" &&
      this.form.cargoTypeStatus === "auto"
    );
  }

  isOpenSelectCargoProduct = false;

  getCargoProductList() {
    CargoController.getCargoProductList({
      status: "active"
    });
  }

  get listProductCargo() {
    const defaultChoices: any = [
      {
        name: "Pilih Produk",
        value: "",
        status: ""
      }
    ];
    const cargos = CargoController.cargoProductList.data.map((e: any) => ({
      name: e.cargoProductTypeCode,
      value: e.cargoProductTypeCode,
      status: e.cargoProductTypeStatus
    }));

    return defaultChoices.concat(cargos);
  }

  onOpenSelectCargoProduct() {
    this.isOpenSelectCargoProduct = true;
  }

  onCloseSelectCargoProduct() {
    this.isOpenSelectCargoProduct = false;
  }

  onSelectCargoProduct(_: string, value: string) {
    this.onCloseSelectCargoProduct();
    CargoController.setOnSelectCargoProduct(value);
  }
  onChangeNog(value: string) {
    CargoController.setOnChangeNog(value);
  }

  onChangeVehicleNumber(value: string) {
    CargoController.setOnChangeVehicleNumber(value);
  }

  onChangeCargoNumber(value: string) {
    CargoController.setUpdateFromBookingCargoNumber(value);
  }

  get isRTC() {
    return ReadyToCargoController.flowBooking.includes('rtc') || this.$route.query.rcId && this.flowBooking === "rebooking" && this.isFromRebookingRTC;
  }

  get isFromRebookingRTC() {
    return CargoController.isRebookingFromRTC;
  }

  get isFillForm() {
    return CargoController.isFillForm;
  }

  get partnerName() {
    return this.dataProfile.account_type_detail.company_name;
  }

  get typeUser() {
    return this.dataProfile.normalizedTypeUser;
  }

  convertDecimal(value: string) {
    return convertDecimalWithComma(value, 2);
  }

  get errorAwb() {
    return NGenController.errorAwb;
  }

  get flightsData() {
    return CargoController.form.flightsData;
  }

  get vehicleNumber() {
    if (this.form.vehicleNumber && !this.isCargoPlaneAuto) {
      return this.form.vehicleNumber;
    }
    return this.isCargoPlaneManual
      ? this.form.vehicleNumber
      : this.flightsData
          .map((item: FlightDetail) => {
            return item.FlightNo;
          })
          .join(", ");
  }

  get showModalTest() {
    return CargoController.isShowModalGamification;
  }
}
